/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { SectionContainer, SectionTitle, SectionGrid } from '../../styled';
import { useTogglable } from 'src/_helpers/useTogglable';
import * as Styled from './styled';

export const CommentsSection = ({ counter, markChanges = true, isCounterForm = false }) => {
	const [isExpanded, { toggle }] = useTogglable(false);
	const { t } = useTranslation();

	const hasThisCounterComments = !!counter.comment_order;

	const hasPreviousComments =
		counter.comments.filter(comment => !comment || Object.keys(comment).length > 0).length >
		(hasThisCounterComments ? 1 : 0);

	const comments = isExpanded ? counter.comments : [counter.comments?.[0]];

	const showNoCommentsAddedMessage = !hasThisCounterComments && !isExpanded;
	const showNoPreviousCommentsMessage =
		!hasPreviousComments && !isCounterForm && !showNoCommentsAddedMessage;

	const handleMoreButtonClick = event => {
		toggle();
		event.target.blur();
	};

	return (
		<SectionContainer data-test="additional-information-section">
			<SectionTitle>{t('comments')}</SectionTitle>
			<SectionGrid $fullWidth>
				{showNoCommentsAddedMessage && (
					<Styled.NoComments>{t('no_comments_added')}</Styled.NoComments>
				)}
				{comments.filter(Boolean).map(comment => {
					const title = comment.index > 0 ? `#${comment.index}` : t('order');

					return Object.keys(comment).length > 0 ? (
						<Styled.Comment
							key={comment.key}
							className={clsx({
								changed: markChanges && comment.changed,
								isOwn: comment.isOwn,
							})}
						>
							<Styled.Header>
								<span>
									{title} - {comment.author}
								</span>
								<span>({moment(comment.time).fromNow()})</span>
							</Styled.Header>
							<Styled.Body>{comment.comment}</Styled.Body>
						</Styled.Comment>
					) : null;
				})}
				{showNoPreviousCommentsMessage && (
					<Styled.NoComments>{t('no_previous_comments')}</Styled.NoComments>
				)}
				{hasPreviousComments && (
					<Styled.Button onClick={handleMoreButtonClick}>
						{isExpanded ? t('show_less_comments') : t('show_all_comments')}
					</Styled.Button>
				)}
			</SectionGrid>
		</SectionContainer>
	);
};
