/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

export const TwoFactorMethod = {
	Email: 'email',
	SMS: 'sms',
};

export const TwoFactorStatus = {
	Available: 'available',
	PhoneNotProvided: 'phone_number_not_provided',
	SMSNotSupported: 'phone_number_country_not_supported',
};

export const OnboardingFormId = {
	Login: 'login-page',
	Registration: 'registration-page',
	Select2FAMethod: 'select-2fa-method',
	AuthCodeForm: 'auth-code-form',
	ConfirmEmail: 'confirmation-email-page',
	ResetEmail: 'reset-email-page',
	ResendEmail: 'resend-confirmation-email-page',
};

export const OnboardingFormWithLogo = [
	OnboardingFormId.Select2FAMethod,
	OnboardingFormId.AuthCodeForm,
	OnboardingFormId.ConfirmEmail,
	OnboardingFormId.ResetEmail,
	OnboardingFormId.ResendEmail,
];

export const UserStatus = {
	APPROVED: 'APPROVED',
	DEACTIVATED: 'DEACTIVATED',
};

export const UserSide = {
	Seller: 'seller',
	Buyer: 'buyer',
};

export const UserRole = {
	Admin: 'admin',
	Support: 'support',
	Broker: 'broker',
};

export const SupportEmail = 'support@vosbor.com';
