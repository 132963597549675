/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { H600 } from 'src/components/Typography/fonts';
import { VolumeDisplay } from 'src/components/Volume';
import { formatPriceWithoutCurrency, getCurrencyAndUnitOfOrderOrCounter } from 'src/_helpers/price';
import { parseDateStringAsUTC, formatDateRange } from 'src/_helpers/date';
import { calculate2ndLegPrice, OrderType, SpreadPriceFormat } from 'src/constants/contract';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import {
	getLabels,
	Labels,
} from 'src/components/ViewOrderDrawer/Content/Paper/ContractAndPricingSection/useCalendarSpreadLegUsers';
import { useOrderDetails } from 'src/_helpers/useOrderDetails';
import { useSellerBrokerBuyer } from 'src/_helpers/order';
import * as Styled from './styled';

export const AcceptSpreadSummary = ({
	order,
	counters = [],
	PrincipalSelectorComponent,
	selectedPrincipal,
}) => {
	const { t } = useTranslation();

	const { brokerCompany, buyerCompany, sellerCompany } = useSellerBrokerBuyer(order, counters);
	const lastCounter = [order, ...counters].at(-1);

	const { isBrokerOrder, isBasisPrice, isSellOrder } = useOrderDetails(order);

	const brokerLabels = getLabels(
		order.order_type === OrderType.Sell ? Labels.Seller : Labels.Buyer
	);

	const shouldShowBuyerPrincipalComponent = !!PrincipalSelectorComponent && isSellOrder;
	const shouldShowSellerPrincipalComponent = !!PrincipalSelectorComponent && !isSellOrder;

	const isPayCashSpread = order.spread_details.price_format === SpreadPriceFormat.PayCash;

	const contractName = `${order.product.name} ${order.inco.name} ${order.primary_ports[0].name}`;
	const price = formatPriceWithoutCurrency(lastCounter.price);
	const spreadPrice = isPayCashSpread
		? formatPriceWithoutCurrency(Math.abs(price))
		: price > 0
		? `+${price}`
		: price;
	const firstLegPrice = lastCounter.spread_details.first_leg_price;

	const secondLegPrice = calculate2ndLegPrice(firstLegPrice, lastCounter.price);
	const currencyUnit = getCurrencyAndUnitOfOrderOrCounter(order, t);

	const getSellerBuyerBrokerLabel = userLabel => {
		return `${t(userLabel)} (${t('broker')})`;
	};

	const hasRunsSupport = order.runs;

	const quantityLabel = hasRunsSupport ? t('total_quantity') : t('quantity');

	return (
		<>
			<Styled.OrderValueRow>
				<Styled.Label>{t('contract')}</Styled.Label>
				<H600>{contractName}</H600>
			</Styled.OrderValueRow>
			<Styled.OrderValueRow>
				<Styled.Label>{quantityLabel}</Styled.Label>
				<VolumeDisplay as={H600} value={lastCounter.volume} />
			</Styled.OrderValueRow>
			{hasRunsSupport && (
				<Styled.OrderValueRow>
					<Styled.Label>{t('runs')}</Styled.Label>
					<H600>{lastCounter.runs}</H600>
				</Styled.OrderValueRow>
			)}
			<Styled.OrderValueRow>
				<Styled.Label>{t('spread')}</Styled.Label>
				<H600>
					{spreadPrice} {currencyUnit}
				</H600>
			</Styled.OrderValueRow>
			<Styled.SectionName>{t('first_leg')}</Styled.SectionName>
			<Styled.OrderValueRow>
				<Styled.Label>{t('seller')}</Styled.Label>
				{shouldShowSellerPrincipalComponent ? (
					PrincipalSelectorComponent
				) : (
					<Styled.CompanyName>
						<OverflowText>{sellerCompany}</OverflowText>
					</Styled.CompanyName>
				)}
			</Styled.OrderValueRow>
			{isBrokerOrder && (
				<Styled.OrderValueRow>
					<Styled.Label>{getSellerBuyerBrokerLabel(brokerLabels[0])}</Styled.Label>
					<Styled.CompanyName>
						<OverflowText>{brokerCompany}</OverflowText>
					</Styled.CompanyName>
				</Styled.OrderValueRow>
			)}
			<Styled.OrderValueRow>
				<Styled.Label>{t('buyer')}</Styled.Label>
				{shouldShowBuyerPrincipalComponent ? (
					PrincipalSelectorComponent
				) : (
					<Styled.CompanyName>
						<OverflowText>{buyerCompany}</OverflowText>
					</Styled.CompanyName>
				)}
			</Styled.OrderValueRow>
			<Styled.OrderValueRow>
				<Styled.Label>{t('shipment')}</Styled.Label>
				<H600>
					{formatDateRange({
						format: order.delivery_mode,
						startDate: parseDateStringAsUTC(order.delivery_date_from),
						endDate: parseDateStringAsUTC(order.delivery_date_to),
					})}
				</H600>
			</Styled.OrderValueRow>
			{isBasisPrice && (
				<Styled.OrderValueRow>
					<Styled.Label>{t('futures_contract')}</Styled.Label>
					<H600>{`${order.futures_contract} ${moment(order.futures_contract_date).format(
						'MMM YYYY'
					)}`}</H600>
				</Styled.OrderValueRow>
			)}
			<Styled.OrderValueRow>
				<Styled.Label>{t('price')}</Styled.Label>
				<H600>
					{formatPriceWithoutCurrency(firstLegPrice)} {currencyUnit}
				</H600>
			</Styled.OrderValueRow>
			<Styled.SectionName>{t('second_leg')}</Styled.SectionName>
			<Styled.OrderValueRow>
				<Styled.Label>{t('seller')}</Styled.Label>
				<Styled.CompanyName>
					<OverflowText>
						{shouldShowBuyerPrincipalComponent
							? selectedPrincipal?.text || '---'
							: buyerCompany}
					</OverflowText>
				</Styled.CompanyName>
			</Styled.OrderValueRow>
			{isBrokerOrder && (
				<Styled.OrderValueRow>
					<Styled.Label>{getSellerBuyerBrokerLabel(brokerLabels[1])}</Styled.Label>
					<Styled.CompanyName>
						<OverflowText>{brokerCompany}</OverflowText>
					</Styled.CompanyName>
				</Styled.OrderValueRow>
			)}
			<Styled.OrderValueRow>
				<Styled.Label>{t('buyer')}</Styled.Label>
				<Styled.CompanyName>
					<OverflowText>
						{shouldShowSellerPrincipalComponent
							? selectedPrincipal?.text || '---'
							: sellerCompany}
					</OverflowText>
				</Styled.CompanyName>
			</Styled.OrderValueRow>
			<Styled.OrderValueRow>
				<Styled.Label>{t('shipment')}</Styled.Label>
				<H600>
					{formatDateRange({
						format: order.spread_details.delivery_mode,
						startDate: parseDateStringAsUTC(order.spread_details.delivery_date_from),
						endDate: parseDateStringAsUTC(order.spread_details.delivery_date_to),
					})}
				</H600>
			</Styled.OrderValueRow>
			{isBasisPrice && (
				<Styled.OrderValueRow>
					<Styled.Label>{t('futures_contract')}</Styled.Label>
					<H600>{`${order.futures_contract} ${moment(
						order.spread_details.futures_contract_date
					).format('MMM YYYY')}`}</H600>
				</Styled.OrderValueRow>
			)}
			<Styled.OrderValueRow>
				<Styled.Label>{t('price')}</Styled.Label>
				<H600>
					{formatPriceWithoutCurrency(secondLegPrice)} {currencyUnit}
				</H600>
			</Styled.OrderValueRow>
		</>
	);
};
