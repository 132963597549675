/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createContext, useContext } from 'react';
import { useTogglable } from 'src/_helpers/useTogglable';
import { useEmit } from 'src/shared/useEmit';
import { ChatEvent } from 'src/shared/constants';
import { HeaderType } from './constants';

export const DrawerContextKeys = {
	viewDrawer: 'viewDrawerState',
	createDrawer: 'createDrawerState',
};

const TemporaryHeaderTimeout = 3000;

export const DrawerContext = createContext({
	containerRef: null,
	myOrderIds: [],
	addMyOrderId: () => {},
	[DrawerContextKeys.viewDrawer]: {
		visibility: [false, {}],
		minimize: [false, {}],
		currentOrderInfo: { orderId: null, environment: null },
		setCurrentOrderInfo: () => {},
		counterPreviewData: null,
		setCounterPreviewData: () => {},
		counterFormEnabled: false,
		enableCounterForm: () => {},
		temporaryHeader: null,
		setTemporaryHeader: () => {},
		isCounterConfirmationVisible: false,
		setCounterConfirmationVisible: () => {},
		isCounterFormDirty: false,
		setIsCounterFormDirty: () => {},
	},
	[DrawerContextKeys.createDrawer]: {
		visibility: [false, {}],
		minimize: [false, {}],
		temporaryHeader: null,
		setTemporaryHeader: () => {},
		copyParams: null,
		orderPreviewData: null,
		setOrderPreviewData: () => {},
		orderPreviewIndex: 0,
		setOrderPreviewIndex: () => {},
		otcRecipients: [],
		setOtcRecipients: () => {},
		isConfirmationVisible: false,
		setConfirmationVisible: () => {},
		isFormDirty: false,
		setIsFormDirty: () => {},
	},
});

export const useDrawerContext = () => useContext(DrawerContext);

export const DrawerProvider = ({ containerRef, children }) => {
	const [
		currentOrderInfo = { orderId: null, environment: null, negotiationId: null },
		setCurrentOrderInfo,
	] = useState(null);
	const viewVisibilityState = useTogglable();
	const viewMinimizedState = useTogglable();
	const createVisibilityState = useTogglable();
	const createMinimizedState = useTogglable();
	const [copyParams, setCopyParams] = useState();
	const [orderPreviewData, setOrderPreviewData] = useState();
	const [counterPreviewData, setCounterPreviewData] = useState();
	const [orderPreviewIndex, setOrderPreviewIndex] = useState(0);
	const [isConfirmationVisible, setConfirmationVisible] = useState(false);
	const [isCounterConfirmationVisible, setCounterConfirmationVisible] = useState(false);
	const [counterFormEnabled, enableCounterForm] = useState(false);
	const [otcRecipients, setOtcRecipients] = useState([]);
	const [isFormDirty, setIsFormDirty] = useState(false);
	const [isCounterFormDirty, setIsCounterFormDirty] = useState(false);

	const [myOrderIds, setMyOrderIds] = useState([]);
	const addMyOrderId = useCallback(id => setMyOrderIds(oldIds => [...oldIds, id]), []);

	const closeChat = useEmit(ChatEvent.Close);

	// overwrite drawer opening methods to automatically close chat
	const openViewDrawer = viewVisibilityState[1].open;
	const closeViewDrawer = viewVisibilityState[1].close;
	viewVisibilityState[1].open = () => {
		closeChat();
		enableCounterForm(false);
		openViewDrawer();
	};

	viewVisibilityState[1].close = () => {
		setCounterPreviewData(undefined);
		closeViewDrawer();
		setIsCounterFormDirty(false);
	};

	const openCreateDrawer = createVisibilityState[1].open;
	createVisibilityState[1].open = params => {
		closeChat();
		setCopyParams(params);
		openCreateDrawer();
	};

	// when the drawer was minimized and is being closed - restore the state to not minimized
	const closeCreateDrawer = createVisibilityState[1].close;
	createVisibilityState[1].close = () => {
		setOrderPreviewData(undefined);
		setOtcRecipients([]);
		closeCreateDrawer();
		createMinimizedState[1].close();
		setOrderPreviewIndex(0);
		setCopyParams(null);
		setIsFormDirty(false);
	};

	const [temporaryHeader, setTemporaryHeader] = useState(null);

	const setDrawerTemporaryHeader = useCallback(header => setTemporaryHeader(header), []);

	useEffect(() => {
		if (temporaryHeader) {
			const clearTemporaryHeader = setTimeout(() => {
				setTemporaryHeader(null);
			}, TemporaryHeaderTimeout);

			return () => {
				clearTimeout(clearTemporaryHeader);
			};
		}
	}, [temporaryHeader]);

	const value = useMemo(
		() => ({
			containerRef,
			myOrderIds,
			addMyOrderId,
			[DrawerContextKeys.viewDrawer]: {
				visibility: viewVisibilityState,
				minimize: viewMinimizedState,
				currentOrderInfo,
				setCurrentOrderInfo,
				counterPreviewData,
				setCounterPreviewData,
				temporaryHeader,
				setTemporaryHeader: setDrawerTemporaryHeader,
				counterFormEnabled,
				enableCounterForm,
				isCounterConfirmationVisible,
				setCounterConfirmationVisible,
				isCounterFormDirty,
				setIsCounterFormDirty,
			},
			[DrawerContextKeys.createDrawer]: {
				visibility: createVisibilityState,
				minimize: createMinimizedState,
				temporaryHeader,
				setTemporaryHeader: setDrawerTemporaryHeader,
				copyParams,
				orderPreviewData,
				setOrderPreviewData,
				orderPreviewIndex,
				setOrderPreviewIndex,
				otcRecipients,
				setOtcRecipients,
				isConfirmationVisible,
				setConfirmationVisible,
				isFormDirty,
				setIsFormDirty,
			},
		}),
		[
			containerRef,
			myOrderIds,
			addMyOrderId,
			viewVisibilityState,
			viewMinimizedState,
			currentOrderInfo,
			counterPreviewData,
			temporaryHeader,
			setDrawerTemporaryHeader,
			createVisibilityState,
			createMinimizedState,
			copyParams,
			orderPreviewData,
			orderPreviewIndex,
			otcRecipients,
			isConfirmationVisible,
			isFormDirty,
			counterFormEnabled,
			isCounterConfirmationVisible,
			isCounterFormDirty,
		]
	);

	return <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>;
};

export const getHeaderTheme = type => {
	switch (type) {
		case HeaderType.Info:
			return {
				background: 'var(--primary-800)',
				color: 'var(--white)',
			};
		case HeaderType.Success:
			return {
				background: 'var(--green-400)',
				color: 'var(--primary-1000)',
			};
		case HeaderType.Warning:
			return {
				background: 'var(--red-400)',
				color: 'var(--white)',
			};
		case HeaderType.MyOrder:
			return {
				background:
					'linear-gradient(180deg, var(--purple-200) -552%, var(--neutral-900) 100%)',
				color: 'var(--white)',
			};
		default:
			return {
				background:
					'linear-gradient(180deg, var(--neutral-300) -552%, var(--neutral-900) 100%)',
				color: 'var(--white)',
			};
	}
};
