/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { MaterialSelectInput } from 'src/designSystem/Form/MaterialInput/MaterialSelectInput';
import { PaperInstruments, Role } from 'src/constants/contract';
import { useContractAndPricingSection } from './useContractAndPricingSection';
import { useWatchPaperFields } from '../../useWatchPaperFields';
import {
	SectionMeta,
	Title,
} from 'src/components/CreateOrderDrawer/components/FoldableSection/styled';
import { toPath } from 'src/_helpers';
import { ReactComponent as DeleteIcon } from 'src/assets/icons/delete.svg';
import { TotalQuantity } from 'src/components/CreateOrderDrawer/components/TotalQuantity/TotalQuantity';
import { MaterialMultiSelectInput } from 'src/designSystem/Form/MaterialInput/MaterialMultiSelectInput';
import {
	SectionGridContent,
	SectionGridLabel,
	SectionGridText,
} from 'src/components/ViewOrderDrawer/styled';
import { tEnvironment } from 'src/constants/contract';
import { CounterpartiesSection } from 'src/components/ViewOrderDrawer/Content/CounterpartiesSection/CounterpartiesSection';
import { UpwardOptionsWrapper } from 'src/designSystem/Form/MultiSelect/styled';
import { OutrightRow } from './outright/OutrightRow';
import { CalendarSpreadRow } from './spread/CalendarSpreadRow';
import { NoApprovedContactsPlaceholder } from 'src/components/CreateOrderDrawer/components/NoApprovedContactsPlaceholder/NoApprovedContactsPlaceholder';
import { usePaperOrderContext } from '../../CreatePaperOrderDrawerContent';
import { Permission } from 'src/containers/Permissions/Permission';
import { OrderCreationPermissions } from 'src/constants/permissions';
import * as Styled from './styled';

export const SingleOrderRow = ({ canRemove, onRemove, path, showRowNumbers, rowNumber }) => {
	const { t } = useTranslation();

	const { getValues, trigger, formState } = useFormContext();

	const title = showRowNumbers ? `${t('quote')} ${rowNumber}` : t('quote');

	const { typeValue, environmentValue, roleValue, instrumentValue } = useWatchPaperFields(path);

	const isBrokerOrder = roleValue === Role.Broker;

	const {
		orderType,
		environment,
		nameVisibility,
		counterparties,
		role,
		shouldDisplayTotalQuantity,
		shouldShowReadOnlyCounterparties,
		principal,
		principalVisibility,
	} = useContractAndPricingSection({ path });

	const { selectedPreset } = usePaperOrderContext();

	const spreadStrategy = useMemo(() => {
		return selectedPreset?.instruments.find(i => i.type === PaperInstruments.Spread)
			?.spread_price_format;
	}, [selectedPreset]);

	return (
		<>
			<SectionMeta>
				<Title>{title}</Title>
			</SectionMeta>
			<div>
				<Styled.FieldContainer>
					{instrumentValue === PaperInstruments.Outright && <OutrightRow path={path} />}
					{instrumentValue === PaperInstruments.Spread && spreadStrategy && (
						<CalendarSpreadRow path={path} spreadStrategy={spreadStrategy} />
					)}
					<Styled.FieldsSeparator />
					<Controller
						name={toPath(path, 'orderType')}
						mandatory
						render={({ field, fieldState: { error } }) => (
							<MaterialSelectInput
								error={!!error}
								data-test={field.name}
								label={t('order_type_label')}
								options={orderType.options}
								disabled={!orderType.ready}
								required
								{...field}
								onChange={value => {
									field.onChange(value);
									if (formState.isSubmitted) {
										trigger();
									}
								}}
							/>
						)}
					/>
					<Controller
						mandatory
						name={toPath(path, 'nameVisibility')}
						render={({ field, fieldState: { error } }) => (
							<MaterialSelectInput
								required
								error={!!error}
								data-test={field.name}
								label={!nameVisibility.ready ? t('your_name') : t('name')}
								options={nameVisibility.options}
								disabled={!nameVisibility.ready}
								{...field}
							/>
						)}
					/>
					{environment.disabled ? (
						<SectionGridContent mandatory>
							<SectionGridLabel>{t('environment')}</SectionGridLabel>
							<SectionGridText data-test="additional-information-section-environment">
								{tEnvironment(t, environmentValue)}
							</SectionGridText>
						</SectionGridContent>
					) : (
						<Controller
							mandatory
							name={toPath(path, 'environment')}
							render={({ field, fieldState: { error } }) => (
								<MaterialSelectInput
									required
									error={!!error}
									data-test={field.name}
									label={t('environment')}
									options={environment.options}
									disabled={!environment.ready}
									{...field}
								/>
							)}
						/>
					)}
					{counterparties.visible && (
						<Controller
							mandatory
							fullWidth
							name={toPath(path, 'counterparties')}
							render={({ field, fieldState: { error } }) => (
								<Styled.WideField>
									<MaterialMultiSelectInput
										key={`counterparties-dropdown${
											counterparties.ready ? '' : '-loading'
										}`}
										error={!!error}
										required
										dataTest={field.name}
										label={t('counterparties')}
										options={counterparties.options}
										groups={counterparties.groups}
										disabled={!counterparties.ready}
										OptionsWrapper={UpwardOptionsWrapper}
										noDataPlaceholder={NoApprovedContactsPlaceholder}
										{...field}
									/>
								</Styled.WideField>
							)}
						/>
					)}
					<Permission
						name={OrderCreationPermissions}
						mustMatchAll
						renderOn={() => (
							<Controller
								mandatory
								name={toPath(path, 'role')}
								render={({ field, fieldState: { error } }) => (
									<MaterialSelectInput
										required
										error={!!error}
										data-test={field.name}
										label={t('role')}
										options={role.options}
										disabled={!role.ready}
										{...field}
									/>
								)}
							/>
						)}
					/>
					{isBrokerOrder && (
						<>
							<Controller
								fullWidth
								mandatory={principal.hasValue}
								name={toPath(path, 'principal')}
								render={({ field, fieldState: { error } }) => (
									<Styled.WideField>
										<MaterialSelectInput
											key={`principal-dropdown${
												principal.ready ? '' : '-loading'
											}`}
											error={!!error}
											data-test={field.name}
											label={t('principal')}
											options={principal.options}
											disabled={!principal.ready}
											upward
											search
											searchIcon
											clearable
											{...field}
										/>
									</Styled.WideField>
								)}
							/>
							<Controller
								mandatory
								name={toPath(path, 'principalVisibility')}
								render={({ field, fieldState: { error } }) => (
									<MaterialSelectInput
										required
										error={!!error}
										data-test={field.name}
										label={t('principal_name')}
										options={principalVisibility.options}
										disabled={!principalVisibility.ready}
										{...field}
									/>
								)}
							/>
						</>
					)}
				</Styled.FieldContainer>
				<Styled.TotalQuantityWrapper>
					<TotalQuantity path={path} shouldRender={shouldDisplayTotalQuantity} />
				</Styled.TotalQuantityWrapper>
				{shouldShowReadOnlyCounterparties && (
					<CounterpartiesSection
						mandatory
						fullWidth
						withSeparator
						order={{
							recipients_list: getValues(toPath(path, 'recipients_list')),
							order_type: typeValue,
						}}
					/>
				)}
				{canRemove && (
					<Styled.RemoveButton onClick={onRemove}>
						<DeleteIcon />
						{t('delete_order')}
					</Styled.RemoveButton>
				)}
			</div>
		</>
	);
};
