/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderDrawerFooter } from '../Drawer/Drawer';
import { Tooltip, TooltipContent, TooltipTrigger } from '../Tooltip/Tooltip';
import { formatValidity } from 'src/_helpers/date';
import { Permission } from 'src/containers/Permissions/Permission';
import { SecondaryButton } from '../Buttons/SecondaryButton';
import { PrimaryButton } from '../Buttons/PrimaryButton';
import { Permissions } from 'src/constants/permissions';
import { useOrderDetails } from 'src/_helpers/useOrderDetails';
import { WithdrawOrderModal } from 'src/containers/OrderDetails/components/WithdrawOrderModal';
import { AcceptOrderButton } from './components/AcceptOrderButton/AcceptOrderButton';
import { CopyOrderButton } from '../CreateOrderDrawer/components/CopyOrderButton/CopyOrderButton';
import { EditOrderButton } from '../CreateOrderDrawer/components/EditOrderButton/EditOrderButton';
import { useViewOrderFooter } from './helpers/useViewOrderFooter';
import { Spinner } from '../Datatable/InfiniteScrollDataTable/styled';
import { useShareOrder } from './helpers/useShareOrder';
import { ShareOrder } from '../Drawer/ShareOrder/ShareOrder';
import { OrderDrawerButtons } from '../Drawer/styled';
import { ChatButton } from './components/ChatButton/ChatButton';
import { useMediaQuery, screenSize } from 'src/_helpers';
import * as Styled from './styled';

export const ViewOrderDrawerFooter = ({ order, negotiations, setCounterForm, isLoading }) => {
	const { t } = useTranslation();

	const { validity } = order;

	const { isMyOrder } = useOrderDetails(order);

	const { expirationData } = useViewOrderFooter(order, negotiations);

	const { shareLink, shareText } = useShareOrder(order);

	return (
		<OrderDrawerFooter>
			<Styled.SectionGridContent>
				<Styled.SectionGridLabel>{expirationData.label}</Styled.SectionGridLabel>
				<Styled.SectionGridText data-test="valid-for">
					{expirationData.value && (
						<Tooltip>
							<TooltipTrigger>{expirationData.value}</TooltipTrigger>
							<TooltipContent>{formatValidity(validity, t)}</TooltipContent>
						</Tooltip>
					)}
				</Styled.SectionGridText>
			</Styled.SectionGridContent>
			{!isLoading ? (
				<OrderDrawerButtons>
					{!!shareText && <ShareOrder shareLink={shareLink} shareText={shareText} />}
					<div>
						{!isMyOrder ? (
							<CounterpartyButtons
								order={order}
								negotiations={negotiations}
								setCounterForm={setCounterForm}
							/>
						) : (
							<OrderOwnerButtons order={order} negotiations={negotiations} />
						)}
					</div>
				</OrderDrawerButtons>
			) : (
				<Spinner />
			)}
		</OrderDrawerFooter>
	);
};

// TODO: Extract to separate files
const Button = ({ children, ...props }) => (
	<SecondaryButton darker {...props}>
		{children}
	</SecondaryButton>
);

const CounterpartyButtons = ({ order, negotiations, setCounterForm }) => {
	const { t } = useTranslation();

	const { isMyCompanyOrder, isActionButtonVisible, canChat } = useOrderDetails(order);

	const {
		goToCounterLink,
		canCounter,
		canAccept,
		canFirmUp,
		canGoToCounter,
		hasOrderUpdated,
		hasTradedNegotiationInOtc,
	} = useViewOrderFooter(order, negotiations);

	const isSmallScreen = useMediaQuery({ 'max-width': screenSize.xl - 1 });

	if (isMyCompanyOrder || !isActionButtonVisible) {
		return;
	}

	return (
		<>
			{canChat && <ChatButton userId={order.user_id} skipIcon={isSmallScreen} />}
			<Permission
				name={Permissions.ORDER_NEGOTIATION}
				renderOn={() => (
					<>
						{canCounter && (
							<Permission
								name={Permissions.ORDER_CREATE_AS_PRINCIPAL}
								renderOn={() => (
									<Styled.CounterButton
										darker
										data-test="counter-order-button"
										disabled={hasOrderUpdated}
										onClick={() => setCounterForm(true, order)}
									>
										{t('counter')}
									</Styled.CounterButton>
								)}
							/>
						)}
						{canGoToCounter && (
							<PrimaryButton to={goToCounterLink} data-test="go-to-counter-button">
								{hasTradedNegotiationInOtc ? t('go_to_trade') : t('go_to_counter')}
							</PrimaryButton>
						)}
						<AcceptOrderButton
							isVisible={canAccept}
							order={order}
							hasOrderUpdated={hasOrderUpdated}
						/>
						{canFirmUp && (
							<Permission
								name={Permissions.ORDER_CREATE_AS_PRINCIPAL}
								renderOn={() => (
									<Styled.AcceptButton
										data-test="firm-up-order-button"
										disabled={hasOrderUpdated}
										onClick={() =>
											setCounterForm(true, {
												...order,
												forceFirm: true,
											})
										}
									>
										{t('firm_up')}
									</Styled.AcceptButton>
								)}
							/>
						)}
					</>
				)}
			/>
		</>
	);
};
const OrderOwnerButtons = ({ order, negotiations }) => {
	const { t } = useTranslation();

	const { _key: orderId, order_type, environment } = order;

	const { canWithdraw, canEdit } = useOrderDetails(order);

	const { hasActiveNegotiations } = useViewOrderFooter(order, negotiations);

	const recipients = order?.recipients || [];

	return (
		<>
			{canWithdraw && (
				<WithdrawOrderModal
					trigger={
						<SecondaryButton
							id="withdraw-order-button"
							data-test="withdraw-order-button"
							darker
						>
							{t('withdraw')}
						</SecondaryButton>
					}
					orderId={orderId}
					orderType={order_type}
					orderVersion={order.version}
					instrument={order.instrument}
					environment={environment}
					market={order.market}
					hasActiveNegotiations={hasActiveNegotiations}
					isDrawer
				/>
			)}
			<CopyOrderButton
				Component={Button}
				orderId={order?._key}
				environment={order?.environment}
				market={order?.market}
				recipients={recipients}
			/>
			{canEdit && (
				<EditOrderButton
					Component={Button}
					orderId={order?._key}
					environment={order?.environment}
					market={order?.market}
					recipients={recipients}
				/>
			)}
		</>
	);
};
