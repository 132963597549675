/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { createSelector } from 'reselect';
import { TwoFactorMethod, UserRole } from 'src/constants/user';

export const userSelector = state => state.user;

export const userAuthLoggedIn = createSelector(userSelector, user => user.isLoggedIn);
export const userAuthLoggingOut = createSelector(userSelector, user => user.isLoggingOut);
export const userAuthRegistered = createSelector(userSelector, user => user.isRegistered);
export const userAuthLoading = createSelector(userSelector, user => user.isLoading);
export const userAuthError = createSelector(userSelector, user => user.error);
export const user2FAMethods = createSelector(userSelector, user => user.mfaMethods);
export const userSelected2FAMethods = createSelector(userSelector, user => {
	const method = user.selected2FAMethod;
	if (method === TwoFactorMethod.SMS) {
		return {
			method,
			phone: user.phone,
		};
	}

	return { method };
});
export const userShowTermsAndConditions = createSelector(
	userSelector,
	user => user.showTermsAndConditions
);
export const getUserFullName = createSelector(userSelector, ({ session }) => ({
	first_name: session.first_name,
	last_name: session.last_name,
}));
export const getUserCompanyId = createSelector(userSelector, ({ session }) => session?.company_id);
export const getUserInitials = createSelector(
	userSelector,
	({ session }) => `${session.first_name[0] || ''}${session.last_name[0] || ''}`
);

export const getUserIsAdmin = createSelector(
	userSelector,
	({ session }) => session.role === UserRole.Admin
);

export const getUserIsSupport = createSelector(
	userSelector,
	({ session }) => session.role === UserRole.Support
);

export const getUserName = createSelector(userSelector, user => user.session.name);
export const getUserId = createSelector(userSelector, user => user.session?._key);
export const getIsCheckingAuth = createSelector(userSelector, user => user.isCheckingAuth);
export const getUserEmail = createSelector(userSelector, user => user.session?.email);

export const getUserPermissions = createSelector(userSelector, user => user.permissions);
export const getUserHomepage = createSelector(userSelector, user => user.homepage);
export const getUserRequestedPath = createSelector(userSelector, user => user?.requestedPath);

export const getUserAvatarColor = createSelector(userSelector, user => user.avatar_color);
export const getUserCompanyName = createSelector(userSelector, user => user.company_name);
export const getUser = createSelector(userSelector, user => user);
export const getUserCompanyAvatarColor = createSelector(
	userSelector,
	user => user.company_avatar_color
);
