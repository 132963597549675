/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useAcceptPhysicalOrderSummary } from './useAcceptPhysicalOrderSummary';
import { SummaryRow } from 'src/containers/Order/common/OrderSummaryModal/SummaryRow';

export const AcceptPhysicalOrderSummary = ({ order, PrincipalSelectorComponent }) => {
	const { dataToDisplay } = useAcceptPhysicalOrderSummary(order, PrincipalSelectorComponent);

	return dataToDisplay.map(({ title, value }, index) => (
		<SummaryRow key={index} title={title} value={value} />
	));
};
