/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { toSemanticSort } from 'src/_helpers/sort';
import { Pagination } from 'src/designSystem/Form/Pagination/Pagination';
import { h400 } from 'src/components/Typography';
import { media } from 'src/_helpers/media-queries';
import { DatatableLoading } from './Loading';
import { EmptyListMessage } from './EmptyListMessage';
import { OverflowHeaderCell } from '../Tooltip/OverflowText';
import { useIsOverflowed } from 'src/_helpers/useIsOverflowed';
import { useScrollVisibility } from './useScrollVisibility';

export const DataTable = ({
	items,
	totalCount,
	isError,
	isLoading,
	sort,
	onSortChange,
	pagination,
	onPaginationChange,
	Row = Table.Row,
	columns,
	noDataToDisplayMessage,
	footer,
	limitOptions,
	'data-test': dataTest,
	scrollFitContentWidth,
	...rest
}) => {
	const { t } = useTranslation();

	useEffect(() => {
		if (!pagination || !items) {
			return;
		}

		if (pagination.activePage > 1 && !isLoading && items.length === 0) {
			onPaginationChange({ activePage: pagination.activePage - 1, limit: pagination.limit });
		}
	}, [isLoading, items, onPaginationChange, pagination]);

	const { scrollableWrapperRef, isScrollVisible } = useScrollVisibility();

	const tableProps = {
		fixed: true,
		sortable: true,
		selectable: true,
		...rest,
	};

	return (
		<StyledTableWrapper data-test={dataTest}>
			<StyledTableForHeader {...tableProps}>
				<StyledTableHeader $isScrollVisible={isScrollVisible}>
					<Table.Row>
						{columns.map(props => (
							<HeaderCell
								onSortChange={onSortChange}
								key={props.name}
								sort={sort}
								{...props}
							/>
						))}
					</Table.Row>
				</StyledTableHeader>
			</StyledTableForHeader>
			<StyledScrolledTableContent
				$scrollFitContentWidth={scrollFitContentWidth}
				ref={scrollableWrapperRef}
			>
				<StyledTable {...tableProps}>
					{isError ? (
						<Table.Body>
							<Table.Row error>
								<Table.Cell>{t('could_not_load')}</Table.Cell>
							</Table.Row>
						</Table.Body>
					) : isLoading ? (
						<Table.Body>
							<DatatableLoading columns={columns} />
						</Table.Body>
					) : items?.length ? (
						<Table.Body>
							{items.map(item => {
								const testAttribute =
									item.market && item.environment
										? `${item?.market}-market-${item?.environment}-order-${item._key}`
										: `row-${item._key || item.contact_user_id}`;
								return (
									<Row
										item={item}
										data-test={testAttribute}
										key={item._key || item.contact_user_id}
									>
										<RowColumns item={item} columns={columns} />
									</Row>
								);
							})}
						</Table.Body>
					) : (
						<EmptyOrders className="no-entries-table-body">
							<Table.Row>
								<NoEntriesCell data-test="no-entries" className="no-entries">
									<EmptyListMessage
										message={noDataToDisplayMessage || t('no_entries')}
									/>
								</NoEntriesCell>
							</Table.Row>
						</EmptyOrders>
					)}
					{pagination && totalCount ? (
						<StyledFooter>
							<Table.Row>
								<StyledPaginationCell colSpan={columns.length}>
									<Pagination
										pagination={pagination}
										totalCount={totalCount}
										setPagination={onPaginationChange}
										limitOptions={limitOptions}
									/>
								</StyledPaginationCell>
							</Table.Row>
						</StyledFooter>
					) : null}
					{footer}
				</StyledTable>
			</StyledScrolledTableContent>
		</StyledTableWrapper>
	);
};

const HeaderCell = ({
	name,
	width,
	label,
	shortLabel,
	disableSort,
	allowedSort,
	alignRightCellText,
	onSortChange,
	sort,
	headerCellStyle = {},
	headerClassName,
}) => {
	return (
		<StyledHeaderCell
			className={alignRightCellText ? 'alignedRight' : 'alignedLeft'}
			style={{
				textAlign: alignRightCellText ? 'right' : 'left',
				...headerCellStyle,
			}}
			$width={width}
			$disableSort={disableSort}
			sorted={sort?._sort === name ? toSemanticSort(sort._order) : null}
			{...(!disableSort && {
				onClick: () => onSortChange(name, allowedSort),
			})}
		>
			<HeaderCellLabel label={label} shortLabel={shortLabel} />
		</StyledHeaderCell>
	);
};

const HeaderCellLabel = ({ label, shortLabel }) => {
	const headerCellRef = useRef();
	const isOverflowed = useIsOverflowed(headerCellRef, null);

	return (
		<OverflowHeaderCell headerCellRef={headerCellRef} tooltipContent={label}>
			<StyledLabel
				ref={headerCellRef}
				className={isOverflowed ? 'label-overflowed' : 'label'}
			>
				<HeaderLabelSpan long={label} short={shortLabel} />
			</StyledLabel>
		</OverflowHeaderCell>
	);
};

const HeaderLabelSpan = ({ long, short }) => {
	const longAndShort = !!long && !!short;
	return (
		<>
			{short && (
				<StyledSpan className={`short-label ${longAndShort ? 'l-s' : ''}`}>
					{short}
				</StyledSpan>
			)}
			{long && (
				<StyledSpan className={`long-label ${longAndShort ? 'l-s' : ''}`}>
					{long}
				</StyledSpan>
			)}
		</>
	);
};

export function RowColumns({ item, columns, ...props }) {
	return columns.map(
		({
			label,
			shortLabel,
			testId,
			width,
			name,
			columnKey,
			disableSort,
			allowedSort,
			alignRightCellText,
			alignCenterCellText,
			headerCellStyle,
			headerClassName,
			Cell,
			...rest
		}) => (
			<Cell
				key={columnKey || name}
				item={item}
				linkTo={item?.linkTo}
				style={{
					textAlign: alignRightCellText
						? 'right'
						: alignCenterCellText
						? 'center'
						: 'left',
				}}
				$width={width}
				{...(testId && { 'data-test': testId })}
				{...rest}
				{...props}
				className={clsx(rest.className, props.className, {
					center: alignCenterCellText,
				})}
			/>
		)
	);
}

const NoEntriesCell = styled(Table.Cell)`
	.ui.table & {
		text-align: center;
		vertical-align: top;
	}
`;

const StyledPaginationCell = styled(Table.HeaderCell)`
	.ui.table th& {
		overflow: visible;
	}
`;

const EmptyOrders = styled(Table.Body)`
	display: flex;
	justify-content: center;
	min-height: 84px;
	color: var(--text-color-default);
	${h400};
	height: 100%;

	& > tr {
		&:hover {
			background: transparent !important;
		}

		td {
			vertical-align: middle !important;
		}
	}

	${media.xl`
		min-height: 168px;
	`}
`;

const StyledTableWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow-x: auto;

	${media.ll`
		overflow-x: hidden;
	`}
`;

export const StyledScrolledTableContent = styled.div`
	overflow-y: auto;
	overflow-x: hidden;
	height: 100%;
	width: ${({ $scrollFitContentWidth }) => ($scrollFitContentWidth ? 'fit-content' : 'auto')};
`;

const StyledTable = styled(Table)`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;

	.ui.table& thead,
	.ui.table& tbody tr {
		display: table;
		width: 100%;
		table-layout: fixed;
	}

	&.ui.table thead {
		th.sorted {
			position: relative;
			overflow: initial;

			&::after {
				display: none;
			}

			&.alignedLeft {
				& .label {
					&::before {
						display: none;
					}

					&::after {
						color: #fff;
						font-family: 'Icons';
						opacity: 0.8;
						height: 1em;
						width: auto;
						margin-left: 5px;
						position: absolute;
					}
				}

				&.ascending {
					& .label {
						&::after {
							content: '\f0d8';
						}
					}
				}

				&.descending {
					& .label {
						&::after {
							content: '\f0d7';
						}
					}
				}

				& .label-overflowed {
					&::after {
						display: none;
					}

					&::before {
						color: #fff;
						font-family: 'Icons';
						opacity: 0.8;
						height: 1em;
						width: auto;
						margin-left: 5px;
						float: right;
					}
				}

				&.ascending {
					& .label-overflowed {
						&::before {
							content: '\f0d8';
						}
					}
				}

				&.descending {
					& .label-overflowed {
						&::before {
							content: '\f0d7';
						}
					}
				}
			}

			&.alignedRight {
				&::after {
					display: none;
				}

				&.label,
				&.label-overflowed {
					&::before {
						color: #fff;
						opacity: 0.8;
						height: 1em;
						width: auto;
						position: relative;
					}
				}

				&.ascending {
					.label,
					.label-overflowed {
						&::before {
							margin: 0 0.5em 0 0;
							font-family: 'Icons';
							content: '\f0d8';
						}
					}
				}

				&.descending {
					.label,
					.label-overflowed {
						&::before {
							margin: 0 0.5em 0 0;
							font-family: 'Icons';
							content: '\f0d7';
						}
					}
				}
			}
		}
	}

	&.ui.table-closed.table-paper-orders tr.nested-row td:first-child:not(.owner-cell) *,
	&.ui.table-closed tr:not(.row-open) .column-product-name *,
	&.ui.table-closed tr:not(.row-open) .column-counter-party *,
	&.ui.table-closed tr:not(.row-open) .column-volume-per-mt * {
		color: var(--neutral-200);
	}

	&.ui.table-paper-orders .paper-child-table tbody {
		background: var(--neutral-900);
	}

	&.ui.table-closed tr:not(.row-open) td.closed-cell.owner-cell * {
		color: var(--purple-900);
	}

	&.ui.table-closed tr:not(.row-open) td:not(:first-child),
	&.ui.table-closed tr:not(.row-open) td * {
		color: var(--neutral-300);
	}
	
	&.ui.table-closed tr.row-closed:not(.row-open) td.column-volume-per-mt:not(:first-child),
	&.ui.table-closed tr.row-closed:not(.row-open) td.column-volume-per-mt * {
		color: var(--neutral-200);
	}

	&.ui.execution-table.table-closed tr td:not(:first-child) * {
		color: var(--neutral-300);
	}

	&.ui.table-closed tr.row-closed:not(.row-open) td.price-color-sell *,
	&.ui.table-closed tr td.price-color-sell *,
	&.ui.execution-table.table-closed tr td.price-color-sell * {
		color var(--sell-closed);
	}
	
	&.ui.table-closed tr.row-closed:not(.row-open) td.price-color-buy *,
	&.ui.table-closed tr td.price-color-buy *,
	&.ui.execution-table.table-closed tr td.price-color-buy * {
		color var(--buy-closed);
	}

	&.ui.execution-table.table-closed tr td:first-child *,
	&.ui.execution-table.table-closed tr td.column-volume-per-mt * {
		color: var(--neutral-200);
	}
`;

const StyledTableForHeader = styled(StyledTable)`
	height: auto !important;
`;

const StyledTableHeader = styled(Table.Header)`
	${({ $isScrollVisible }) =>
		$isScrollVisible ? 'padding-right: var(--scrollbar-width);' : null}
`;

const StyledFooter = styled(Table.Footer)`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const StyledHeaderCell = styled(Table.HeaderCell)`
	${props =>
		props.$disableSort
			? `
			cursor: default !important;
			&:hover {
				background: var(--neutral-850) !important;
				color: var(--text-color-secondary) !important;
			}
			`
			: `
				cursor: pointer;
			`}

	${props => (typeof props?.$width === 'number' ? `width: ${props.$width}px !important;` : '')};
	${props => (props?.$width?.default ? `width: ${props.$width.default}px !important;` : '')};
	${media.s`
		${props => (props?.$width?.s ? `width: ${props.$width.s}px !important;` : '')}
	`}
	${media.m`
		${props => (props?.$width?.m ? `width: ${props.$width.m}px !important;` : '')}
	`}
	${media.l`
		${props => (props?.$width?.l ? `width: ${props.$width.l}px !important;` : '')}
	`}
	${media.ll`
		${props => (props?.$width?.ll ? `width: ${props.$width.ll}px !important;` : '')}
	`}
	${media.xl`
		${props => (props?.$width?.xl ? `width: ${props.$width.xl}px !important;` : '')}
	`}
`;

const StyledLabel = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const StyledSpan = styled.span`
	&.short-label.l-s {
		${media.ll`
			display: none;
		`}
	}
	&.long-label.l-s {
		display: none;
		${media.ll`
			display: inline;
		`}
	}
`;
