/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useMemo, cloneElement } from 'react';
import { useTranslation } from 'react-i18next';
import { SpreadPriceFormat } from 'src/constants/contract';

import { volumeField } from './fields/VolumeField';
import { spreadTypeField } from './fields/SpreadTypeField';
import { spreadPriceField } from './fields/SpreadPriceField';
import { firstLegPriceField } from './fields/FirstLegPriceField';
import { firstLegShipmentField } from './fields/FirstLegShipmentField';
import { netBalanceField } from './fields/NetBalanceField';
import { secondLegPriceField } from './fields/SecondLegPriceField';
import { secondLegPayCashPriceField } from './fields/SecondLegPayCashPriceField';
import { secondLegShipmentField } from './fields/SecondLegShipmentField';
import { runsField } from './fields/RunsField';
import { secondLegMonth } from './fields/common/SecondLegMonth';
import { totalQuantityField } from './fields/TotalQuantityField';
import { secondLegFuturesMonth } from './fields/common/SecondLegFuturesMonth';
import { firstLegQuote } from './fields/common/FirstLegQuote';
import { firstLegMonth } from './fields/common/FirstLegMonth';
import { firstLegFuturesMonth } from './fields/common/FirstLegFuturesMonth';
import { secondLegQuote } from './fields/common/SecondLegQuote';

const createFieldWithProps = (fieldComponentFn, path, t, index) =>
	cloneElement(fieldComponentFn(path, t), { key: `${path}-${index}` });

const getSpreadFields = (fields, path, t) =>
	fields.map((fieldComponentFn, index) => createFieldWithProps(fieldComponentFn, path, t, index));

export const useSpreadStrategy = (path, spreadStrategy) => {
	const { t } = useTranslation();

	const spreadStrategies = useMemo(
		() => ({
			[SpreadPriceFormat.Spread]: [
				firstLegQuote,
				firstLegShipmentField,
				firstLegPriceField,
				secondLegQuote,
				secondLegShipmentField,
				secondLegPriceField,
				spreadPriceField,
				runsField,
				totalQuantityField,
			],
			[SpreadPriceFormat.PayCash]: [
				firstLegQuote,
				firstLegMonth,
				firstLegFuturesMonth,
				secondLegQuote,
				secondLegMonth,
				secondLegFuturesMonth,
				spreadTypeField,
				spreadPriceField,
				netBalanceField,
				volumeField,
				firstLegPriceField,
				secondLegPayCashPriceField,
			],
		}),
		[]
	);

	const spreadStrategyFields = useMemo(
		() => getSpreadFields(spreadStrategies[spreadStrategy], path, t),
		[spreadStrategies, spreadStrategy, path, t]
	);

	return {
		spreadStrategyFields,
	};
};
