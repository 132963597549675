/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserPermissions } from 'src/_store/selectors';

export const PermissionsContext = createContext({
	permissions: [],
	setPermissions: () => {},
	hasPermission: () => {},
	arePermissionsLoaded: false,
});

export const PermissionsContextProvider = ({ children, forcePermissions = [] }) => {
	const [arePermissionsLoaded, setPermissionsLoaded] = useState(forcePermissions.length > 0);
	const [permissions, setPermissions] = useState(
		forcePermissions.reduce((list, item) => {
			list[item] = true;
			return list;
		}, {})
	);

	const hasPermission = (name, mustMatchAll = false) => {
		if (Array.isArray(name)) {
			if (mustMatchAll) {
				return name.every(n => permissions[n] === true);
			}

			return name.some(n => permissions[n] === true);
		}

		return permissions[name] === true;
	};

	const permissionsList = useSelector(getUserPermissions);

	useEffect(() => {
		if (forcePermissions.length === 0 && permissionsList) {
			setPermissions(permissionsList);
			setPermissionsLoaded(true);
		}
	}, [forcePermissions, permissionsList]);

	return (
		<PermissionsContext.Provider
			value={{
				permissions,
				setPermissions,
				hasPermission,
				arePermissionsLoaded,
			}}
		>
			{children}
		</PermissionsContext.Provider>
	);
};

export const usePermissionContext = () => useContext(PermissionsContext);
