/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { usePermissionContext } from './PermissionsContext';

export const Permission = ({ name, mustMatchAll, renderOn, renderOff }) => {
	const { arePermissionsLoaded, hasPermission } = usePermissionContext();

	if (arePermissionsLoaded) {
		if (hasPermission(name, mustMatchAll) && renderOn) {
			return renderOn(name);
		}

		if (!hasPermission(name, mustMatchAll) && renderOff) {
			return renderOff(name);
		}
	}

	return null;
};
