/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'src/designSystem/Form/Select/Select';
import * as Styled from './styled';

export const PrincipalSelectorDropdown = ({ options, principalId, setPrincipalId }) => {
	const { t } = useTranslation();

	useEffect(() => {
		return () => setPrincipalId(null);
	}, [setPrincipalId]);

	return (
		<Styled.Wrapper className={principalId && 'filled'}>
			<Select
				id="principal-selector"
				placeholder={`${t('principal')} *`}
				options={options}
				direction="left"
				clearable={false}
				onChange={setPrincipalId}
			/>
			{principalId && <Styled.ValidCheckIcon />}
		</Styled.Wrapper>
	);
};
