/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import React from 'react';
import { FlagNames } from 'src/constants/flags';
import { Permission } from 'src/containers/Permissions/Permission';
import { AddUsersToTradeButton } from '../../AddUsersToTradeButton/AddUsersToTradeButton';
import { Flags } from 'src/featureFlags/Flags';
import { OrderCreationPermissions } from 'src/constants/permissions';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';
import { CopyOrderButton } from 'src/components/CreateOrderDrawer/components/CopyOrderButton/CopyOrderButton';
import * as Styled from './styled';

export const ButtonsSection = ({ tradeData }) => {
	const {
		_key: tradeId,
		environment,
		market,
		tradeUsersIds,
		buyer_user_id,
		seller_user_id,
		brokerId,
	} = tradeData;
	const currentUserId = useSelector(getUserId);

	const OTCNominee = !!brokerId
		? ['']
		: [buyer_user_id, seller_user_id].filter(id => id !== currentUserId).filter(Boolean);

	return (
		<Styled.ButtonsSectionWrapper data-test="trade-details-button-section">
			<Flags
				authorizedFlags={[FlagNames.MultiTenancy]}
				renderOn={() => (
					<AddUsersToTradeButton usersAlreadyInTrade={tradeUsersIds} tradeId={tradeId} />
				)}
			/>
			<Permission
				name={OrderCreationPermissions}
				renderOn={() => (
					<CopyOrderButton
						Component={CopyButton}
						tradeId={tradeId}
						environment={environment}
						market={market}
						recipients={OTCNominee}
					/>
				)}
			/>
		</Styled.ButtonsSectionWrapper>
	);
};

const CopyButton = ({ children, ...props }) => (
	<Styled.CopyButton darker fixedPadding {...props}>
		{children}
	</Styled.CopyButton>
);
