/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { usePermissionContext } from 'src/containers/Permissions/PermissionsContext';
import { Permissions } from 'src/constants/permissions';
import { sortOrder } from 'src/_helpers/sort';
import { listOrderBrokerContacts } from 'src/_api/orders.api';

export const useAcceptOrderAsBroker = (order, shouldFetchContacts = false) => {
	const [principalId, setPrincipalId] = useState(null);
	const { hasPermission } = usePermissionContext();
	const { t } = useTranslation();

	const userHasBrokerRole = hasPermission(Permissions.ORDER_CREATE_AS_BROKER);

	const { data = { contacts: [] } } = useQuery(
		[
			`list-broker-contacts-${order._key}`,
			{
				_limit: 1000,
				is_my_region: true,
				_sort: 'name',
				_order: sortOrder.asc,
				exclude_my_company: true,
			},
		],
		({ queryKey: [, params] }) =>
			listOrderBrokerContacts(order.environment, order._key, params),
		{
			enabled: shouldFetchContacts,
		}
	);

	const principalOptions = useMemo(() => {
		return data?.contacts?.map(contact => ({
			text: `${contact.name} - ${contact.company_name}`,
			key: contact.contact_user_id,
			value: contact.contact_user_id,
			// Note: all additional values are written directly to HTML as node attributes, so we have to use the "data-" to prevent warning
			'data-approved': contact.mutually_approved_with_order_principal,
		}));
	}, [data?.contacts]);

	const selectedPrincipal =
		principalId && principalOptions
			? principalOptions.find(({ value }) => value === principalId)
			: null;

	const isPrincipalAcceptedContact = !!selectedPrincipal?.['data-approved'];

	const canAcceptOrder = !userHasBrokerRole || (principalId && isPrincipalAcceptedContact);

	const getAcceptButtonTooltip = () => {
		if (canAcceptOrder) {
			return null;
		}

		if (!principalId) {
			return t('select_principal_to_accept');
		}

		if (!isPrincipalAcceptedContact) {
			return t('principal_need_to_be_approved_counterparties');
		}
	};

	return {
		userHasBrokerRole,
		canAcceptOrder,
		acceptButtonTooltip: getAcceptButtonTooltip(),
		principalId,
		setPrincipalId,
		principalOptions,
		selectedPrincipal,
	};
};
