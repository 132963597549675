/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ErrorIcon } from 'src/assets/icons/red_circle_cross.svg';
import { DatatableLoading } from '../Loading';
import { EmptyListMessage } from '../EmptyListMessage';
import { HeaderCell, RowColumns, DefaultTableRow } from './DataTableComponents';
import * as Styled from './styled';

export const InfiniteScrollDataTable = ({
	items,
	isError,
	isLoading,
	sort,
	onSortChange,
	Row = DefaultTableRow,
	columns,
	noDataToDisplayMessage,
	footer,
	'data-test': dataTest,
	scrollFitContentWidth,
	fetchNextPage,
	hasNextPage,
	scrollGutter = true,
	wrapperClassName,
	...rest
}) => {
	const { t } = useTranslation();

	const nextPageObserverRef = useRef(null);

	useEffect(() => {
		const currentTarget = nextPageObserverRef?.current;

		if (!currentTarget) {
			return;
		}

		const observer = new IntersectionObserver(entries => {
			if (entries[0].isIntersecting && !isLoading && !isError) {
				fetchNextPage();
			}
		});

		observer.observe(currentTarget);

		return () => {
			if (currentTarget) {
				observer.unobserve(currentTarget);
			}
		};
	}, [fetchNextPage, isLoading, isError]);

	const tableProps = {
		fixed: true,
		sortable: true,
		selectable: true,
		...rest,
	};

	const isLoadingFirstPage = items === undefined && hasNextPage === undefined && isLoading;
	const isLoadingNextPage = items !== undefined && items.length > 0 && isLoading;
	const isEmpty = items?.length === 0 && hasNextPage === false;

	return (
		<Styled.TableWrapper data-test={dataTest} className={wrapperClassName}>
			<Styled.Table
				{...tableProps}
				className={clsx(
					{ 'no-entries-table': isEmpty },
					tableProps.className && tableProps.className
				)}
			>
				<Styled.TableHeader>
					<tr>
						{columns.map(props => (
							<HeaderCell
								onSortChange={onSortChange}
								key={props.columnKey || props.name}
								sort={sort}
								{...props}
							/>
						))}
					</tr>
				</Styled.TableHeader>
				{isLoadingFirstPage ? (
					<tbody className="loading">
						<DatatableLoading columns={columns} />
					</tbody>
				) : isEmpty ? (
					<Styled.EmptyOrders className="no-entries-table-body">
						<tr>
							<Styled.NoEntriesCell data-test="no-entries" className="no-entries">
								<EmptyListMessage
									message={noDataToDisplayMessage || t('no_entries')}
								/>
							</Styled.NoEntriesCell>
						</tr>
					</Styled.EmptyOrders>
				) : (
					<>
						<Styled.TableBody>
							{items?.map(item => {
								const testAttribute =
									item.market && item.environment
										? `${item?.market}-market-${item?.environment}-order-${item._key}`
										: `row-${item._key || item.contact_user_id}`;
								return (
									<Row
										item={item}
										data-test={testAttribute}
										key={item.reactKey || item._key || item.contact_user_id}
									>
										<RowColumns item={item} columns={columns} />
									</Row>
								);
							})}
						</Styled.TableBody>
						{isError && (
							<Styled.TableBody>
								<tr className="error">
									<td>
										<div>
											<ErrorIcon />
											<span>{t('could_not_load')}</span>
										</div>
									</td>
								</tr>
							</Styled.TableBody>
						)}
					</>
				)}
				{footer}
			</Styled.Table>
			<Styled.NextPageObserver ref={nextPageObserverRef} $collapsed={!hasNextPage}>
				{isLoadingNextPage && <Styled.Spinner />}
			</Styled.NextPageObserver>
		</Styled.TableWrapper>
	);
};
