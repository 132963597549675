/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { ReactComponent as CheckIcon } from 'src/assets/icons/check16x16.svg';

export const Wrapper = styled.div`
	width: 261px;
	position: relative;

	.ui.selection.dropdown {
		background: transparent;

		.default.text {
			color: var(--neutral-100) !important;
		}

		&:has(.default.test) {
			border: 1px solid var(--neutral-600);
		}

		&:has(.divider.text:not(.default)) {
			border: 1px solid var(--green-400);
		}
	}

	&.filled {
		.dropdown.icon {
			display: none;
		}
	}
`;

export const ValidCheckIcon = styled(CheckIcon)`
	position: absolute;
	right: 13px;
	top: 50%;
	transform: translateY(-50%);
	color: var(--green-400);
	pointer-events: none;
`;
