/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';

export const CalendarWrapper = styled.div`
	&:nth-child(3n) {
		.date-selector-popup {
			right: 0;

			&::before {
				right: 16px;
				left: unset;
			}
		}
	}
	&:nth-child(3n + 1) {
		.date-selector-popup {
			left: 0;

			&::before {
				left: 16px;
				right: unset;
			}
		}
	}
`;
