/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { media } from 'src/_helpers';
import { ReactComponent as Info } from 'src/assets/icons/info.svg';
import { h600, h400 } from 'src/components/Typography';

export const PrincipalContainer = styled.div`
	display: flex;
	align-items: start;
	gap: 4px;
	width: 100%;

	& > span {
		width: inherit;
	}

	&.changed .user-details {
		position: relative;
		z-index: 1;

		&:before {
			content: '';
			background-color: var(--counter-highlight-color);
			inset: -6.5px -6px;
			border-radius: 8px;
			position: absolute;
			z-index: -1;

			${media.xl`
				border-radius: 16px;
			`}
		}
	}

	.user-avatar-company-name {
		max-width: 124px;

		${media.ll`
			max-width: 150px;
		`}

		${media.xl`
			max-width: 168px;
		`}
	}

	.user-avatar-details {
		margin-left: 10px;

		${media.xl`
			margin-left: 12px;
		`}
	}

	.user-avatar-username {
		${h600}
		padding-top: 0;
	}

	${({ $isTraded }) =>
		$isTraded &&
		`
		.user-details {
			width: 100%;
		}
  `}
`;

export const InfoIcon = styled(Info)`
	width: 20px;
	height: 20px;
	color: var(--neutral-300);
`;

export const BrokerContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const BrokerUser = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	width: 100%;
	${h400}

	label {
		color: var(--text-color-secondary);
	}
`;

export const BrokerDetails = styled.div`
	display: flex;
	gap: 4px;

	.content {
		max-width: calc(50% - 4px);
	}

	.separator {
		max-width: 4px;
	}
`;
