/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { Environment } from './contract';

export const myOrdersColumnWidths = {
	product: { default: 80 + 22, ll: 90 + 22, xl: 100 + 22 },
	origin: { default: 57, ll: 77, xl: 96 },
	company: { default: 67, xl: 96 },
	inco: { default: 47, xl: 56 },
	port: { default: 87, xl: 106 },
	shipment: { default: 97, xl: 146 },
	volume: { default: 57, xl: 66 },
	price: { default: 78 },
	currency: { default: 65, xl: 66 },
	futures: { default: 57, xl: 66 },
	order_type: { default: 47, xl: 56 },
	is_indicative: { default: 98, xl: 106 },
	validity: { default: 77, xl: 96 },
	updated_at: { default: 93, xl: 88 },
};

export const myOrdersExpandedColumnWidths = {
	product: { default: 70 + 40, ll: 80 + 40, xl: 100 + 40 },
	origin: { default: 60, ll: 70, xl: 80 },
	company: { default: 70, xl: 80 },
	trader: { xl: 80 },
	name_visibility: { default: 60 },
	inco: { default: 30, xl: 40 },
	port: { default: 60, ll: 70, xl: 90 },
	shipment: { default: 60, ll: 80, xl: 90 },
	runs: { default: 30 },
	volume: { default: 50 },
	price: { default: 70 },
	currency: { default: 50 },
	futures: { default: 40, xl: 50 },
	order_type: { default: 40 },
	is_indicative: { default: 90 },
	validity: { default: 70, xl: 80 },
	updated_at: { default: 70, xl: 80 },
};

export const myOrderHistoryExpandedColumnWidths = {
	product: { default: 70 + 40, ll: 80 + 40, xl: 100 + 40 },
	origin: { default: 60, ll: 70, xl: 80 },
	company: { default: 70, xl: 80 },
	trader: { xl: 80 },
	name_visibility: { default: 60 },
	inco: { default: 30, xl: 40 },
	port: { default: 60, ll: 70, xl: 90 },
	shipment: { default: 60, ll: 80, xl: 90 },
	runs: { default: 30 },
	volume: { default: 50 },
	price: { default: 70 },
	currency: { default: 50 },
	futures: { default: 40, xl: 50 },
	order_type: { default: 40 },
	is_indicative: { default: 90 },
	updated_at: { default: 70, xl: 80 },
	fill_cell: { default: 70, xl: 80 },
	actions: { default: 76, ll: 106, xl: 86 },
};

export const myNegotiationsColumnWidths = {
	product: { default: 82, ll: 97, xl: 92 },
	origin: { default: 57, ll: 77, xl: 96 },
	company: { default: 67, xl: 96 },
	inco: { default: 47, xl: 56 },
	port: { default: 87, xl: 106 },
	shipment: { default: 97, xl: 146 },
	volume: { default: 57, xl: 66 },
	price: { default: 67, xl: 76 },
	currency: { default: 57, xl: 66 },
	futures: { default: 57, xl: 66 },
	order_type: { default: 47, xl: 56 },
	is_indicative: { default: 67, xl: 76 },
	updated_at: { default: 87, xl: 96 },
	validity: { default: 77, xl: 86 },
};

export const myNegotiationsExpandedColumnWidths = {
	product: { default: 70 + 40, ll: 80 + 40, xl: 100 + 40 },
	origin: { default: 60, ll: 70, xl: 80 },
	company: { default: 110 },
	trader: { default: 110 },
	inco: { default: 30, xl: 40 },
	port: { default: 60, ll: 70, xl: 90 },
	shipment: { default: 60, ll: 80, xl: 90 },
	runs: { default: 30 },
	volume: { default: 50 },
	price: { default: 60 },
	currency: { default: 50 },
	futures: { default: 40, ll: 50 },
	order_type: { default: 40 },
	is_indicative: { default: 90, ll: 100 },
	updated_at: { default: 70, xl: 80 },
	validity: { default: 70, xl: 80 },
};

export const myOrderHistoryColumnWidth = {
	product: { default: 80 + 22, ll: 90 + 22, xl: 100 + 22 },
	origin: { default: 57, ll: 77, xl: 96 },
	company: { default: 67, xl: 96 },
	inco: { default: 47, xl: 56 },
	port: { default: 87, xl: 106 },
	shipment: { default: 97, xl: 146 },
	volume: { default: 57, xl: 66 },
	price: { default: 78 },
	currency: { default: 65, xl: 66 },
	futures: { default: 57, xl: 66 },
	order_type: { default: 47, xl: 56 },
	is_indicative: { default: 98, xl: 106 },
	updated_at: { default: 97, xl: 96 },
	fill_cell: { default: 73, xl: 88 },
};

export const myNegotiationHistoryColumnWidth = {
	product: { default: 82, ll: 97, xl: 92 },
	origin: { default: 57, ll: 77, xl: 96 },
	company: { default: 67, xl: 96 },
	inco: { default: 47, xl: 56 },
	port: { default: 87, xl: 106 },
	shipment: { default: 97, xl: 146 },
	volume: { default: 57, xl: 66 },
	price: { default: 67, xl: 76 },
	currency: { default: 57, xl: 66 },
	futures: { default: 57, xl: 66 },
	order_type: { default: 47, xl: 56 },
	is_indicative: { default: 67, xl: 76 },
	closed_at: { default: 87, xl: 96 },
	fill_cell: { default: 77, xl: 86 },
};

export const myNegotiationsHistoryExpandedColumnWidths = {
	product: { default: 70 + 40, ll: 80 + 40, xl: 100 + 40 },
	origin: { default: 60, ll: 70, xl: 80 },
	company: { default: 110 },
	trader: { default: 110 },
	inco: { default: 30, xl: 40 },
	port: { default: 60, ll: 70, xl: 90 },
	shipment: { default: 60, ll: 80, xl: 90 },
	runs: { default: 30 },
	volume: { default: 50 },
	price: { default: 60 },
	currency: { default: 50 },
	futures: { default: 40, ll: 50 },
	order_type: { default: 40 },
	is_indicative: { default: 90, ll: 100 },
	closed_at: { default: 70, xl: 80 },
	fill_cell: { default: 70, xl: 80 },
};

export const myTradesColumnWidth = {
	product: { default: 84, ll: 99, xl: 96 },
	origin: { default: 67, xl: 96 },
	counterparty: { default: 87, xl: 96 },
	created_at: { default: 87, xl: 96 },
	inco: { default: 47, xl: 56 },
	port: { default: 87, xl: 126 },
	shipment: { default: 97, xl: 106 },
	volume: { default: 67, xl: 76 },
	position: { default: 57, xl: 66 },
	price: { default: 67, xl: 76 },
	currency: { default: 57, ll: 67, xl: 76 },
	futures: { default: 67, ll: 87, xl: 126 },
	market_price: { default: 67, xl: 76 },
	profit_and_loss: { default: 77, ll: 67, xl: 76 },
};

export const myTradesExpandedColumnWidth = {
	product: { default: 70 + 40, ll: 80 + 40, xl: 100 + 40 },
	origin: { default: 60, ll: 70, xl: 80 },
	counterparty: { default: 70, ll: 80, xl: 120 },
	trader: { default: 80, ll: 80, xl: 120 },
	created_at: { default: 70, xl: 80 },
	inco: { default: 40 },
	port: { default: 70, ll: 80, xl: 90 },
	shipment: { default: 80, ll: 100 },
	runs: { default: 30 },
	volume: { default: 50, xl: 80 },
	position: { default: 60, xl: 100 },
	price: { default: 60 },
	currency: { default: 50 },
	futures: { default: 50 },
	market_price: { default: 70, ll: 80 },
	profit_and_loss: { default: 70, ll: 70, xl: 80 },
};

export const physicalOrderbookColumnWidths = {
	product: { default: 80 + 22, ll: 90 + 22, xl: 100 + 22 },
	origin: { default: 57, ll: 77, xl: 96 },
	company: { default: 67, xl: 96 },
	inco: { default: 47, xl: 56 },
	port: { default: 87, xl: 106 },
	shipment: { default: 97, xl: 146 },
	volume: { default: 57, xl: 66 },
	price: { default: 78 },
	currency: { default: 65, xl: 66 },
	futures: { default: 57, xl: 66 },
	order_type: { default: 47, xl: 56 },
	is_indicative: { default: 98, xl: 106 },
	created_at: { default: 77, xl: 96 },
	validity: { default: 93, xl: 88 },
};

export const physicalOrderbookExpandedColumnWidths = {
	product: { default: 100, xl: 120 },
	origin: { default: 80, ll: 100 },
	trader: { xl: 120 },
	company: { default: 90, ll: 100, xl: 120 },
	inco: { default: 50, xl: 60 },
	port: { default: 100, ll: 120, xl: 160 },
	shipment: { default: 100, ll: 120, xl: 160 },
	volume: { default: 60 },
	price: { default: 60 },
	currency: { default: 60 },
	futures: { default: 50, ll: 60 },
	order_type: { default: 60 },
	is_indicative: { default: 100 },
	created_at: { default: 80, xl: 100 },
	validity: { default: 80, xl: 100 },
};

export const paperOrderbookColumnWidths = {
	paper_contract_name: { default: 90 + 22 },
	shipment_and_spread: { default: 110, ll: 104, xl: 116 },
	futures: { default: 56, xl: 62 },
	last_traded_price: { default: 60, xl: 70 },
	update_date: { default: 68, xl: 76 },
	company: { default: 60, xl: 96 },
	volume: { default: 50, ll: 60, xl: 76 },
	bid_offer_count_type: { default: 85, ll: 95, xl: 106 },
	bid_offer: { default: 85, xl: 96 },
};

export const paperOrderbookExpandedColumnWidths = {
	paper_contract_name: { default: 113 },
	shipment_and_spread: { default: 95 },
	futures: { default: 40, xl: 50 },
	last_traded_price: { default: 50 },
	update_date: { default: 60, xl: 70 },
	closing_date: { default: 60, xl: 70 },
	trader: { xl: 100 },
	company: { default: 60, ll: 70, xl: 90 },
	volume: { default: 50, ll: 60 },
	bid_offer_count_type: { default: 90, ll: 100 },
	bid_offer: { default: 70 },
};

export const dashboardFiltersDelay = 500;

export const forexColumnWidths = {
	currency_pair: { default: 85, ll: 90, xl: 160 },
	last_price: { default: 70, ll: 90, xl: 160 },
	change: { ll: 90, xl: 160 },
	change_percent: { default: 100, ll: 90, xl: 160 },
};

export const Orderbook = 'orderbook';

export const EnvironmentDashboardFilters = {
	All: 'all',
	[Environment.Exchange]: Environment.Exchange,
	[Environment.OTC]: Environment.OTC,
};
