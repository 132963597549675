/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAllPaperPresets } from 'src/components/SearchHeader/useAllPaperPresets';
import { PaperInstruments, tPaperInstrument } from 'src/constants/contract';

export const useProductCounterFields = () => {
	const { t } = useTranslation();
	const { data } = useAllPaperPresets();

	const presets = useMemo(() => data?.presets || [], [data?.presets]);

	const productsPresets = useMemo(
		() =>
			presets
				.map(preset => {
					return {
						text: `${preset.product.name} ${preset.inco_id} ${
							preset.loading_port?.name || preset.discharging_port?.name
						}`,
						value: preset._key,
					};
				})
				.sort((a, b) => a.text.localeCompare(b.text)),
		[presets]
	);

	const instrumentOptions = Object.values(PaperInstruments).map(instrument => ({
		text: tPaperInstrument(t, instrument),
		key: instrument,
		value: instrument,
	}));

	return {
		productsPresets: {
			options: productsPresets,
			counterable: false,
		},
		instruments: { options: instrumentOptions, counterable: false },
	};
};
