/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import {
	PriceType,
	QuoteType,
	NameVisibility,
	DrawerPriceType,
	OrderType,
	PaperInstruments,
	Role,
	SpreadPriceFormat,
} from 'src/constants/contract';
import { getNumberOfMonths } from 'src/_helpers/date';
import { getChangesBetweenCounters } from 'src/components/ViewOrderDrawer/helpers/getCounterChanges';
import { defaultValidity } from 'src/components/CreateOrderDrawer/paper/model';
import { calculatePayCashDirection } from 'src/_helpers/spread';

export const mapLastCounterToFormData = (lastCounter, userId) => {
	const instrument = lastCounter.instrument;

	const orderCounterType = lastCounter.counter_order_type || lastCounter.order_type;

	const isBuyerInCurrentCounter = orderCounterType === OrderType.Sell;

	const isPayCashSpread = lastCounter.spread_details?.price_format === SpreadPriceFormat.PayCash;

	const spreadDetails =
		instrument === PaperInstruments.Spread
			? {
					firstLegFuturesMonth: lastCounter.firstLegFuturesMonth,
					firstLegMonth: lastCounter.firstLegMonth,
					firstLegPrice: lastCounter.firstLegPrice || '',
					secondLegFuturesMonth: lastCounter.secondLegFuturesMonth,
					secondLegMonth: lastCounter.secondLegMonth,
					spreadType: isPayCashSpread
						? calculatePayCashDirection(isBuyerInCurrentCounter, lastCounter.price)
						: undefined,
					spreadMeta: {
						orderCounterType,
						user: lastCounter.user,
						shouldShowUser: !lastCounter.hidden || lastCounter.is_identity_revealed,
					},
					price: isPayCashSpread
						? Math.abs(lastCounter.price).toString()
						: lastCounter.price.toString(),
			  }
			: {};

	return {
		presetID: lastCounter.preset_id,
		instrument,
		contractPricing: [
			{
				orderType: lastCounter.is_indicative ? QuoteType.Indicative : QuoteType.Firm,
				type: lastCounter.order_type,
				environment: lastCounter.environment,
				role: lastCounter.role,
				nameVisibility: lastCounter.hidden ? NameVisibility.Hidden : NameVisibility.Visible,
				delivery: {
					startDate: lastCounter.delivery_date_from,
					endDate: lastCounter.delivery_date_to,
					format: lastCounter.delivery_mode,
				},
				priceType:
					lastCounter.price_type === PriceType.Flat
						? DrawerPriceType.Flat
						: lastCounter.futures_contract,
				price: lastCounter.price,
				...(lastCounter.runs && { runs: lastCounter.runs.toString() }),
				volume:
					lastCounter.volume /
					getNumberOfMonths(lastCounter.delivery_date_from, lastCounter.delivery_date_to),
				totalVolume: lastCounter.volume,
				futuresMonth: lastCounter.futures_contract_date
					? new Date(lastCounter.futures_contract_date).valueOf()
					: undefined,
				currencyUnit: `${lastCounter.currency}/${lastCounter.price_unit}`,
				order_owner_principal_id: lastCounter.order_owner_principal_id,
				principalVisibility:
					lastCounter.order_owner_principal.user &&
					lastCounter.order_owner_principal.hidden
						? NameVisibility.Hidden
						: NameVisibility.Visible,
				...(lastCounter.order_owner_principal?.user && {
					principalId: lastCounter.order_owner_principal.user._key,
				}),
				...spreadDetails,
			},
		],
		order_user_id: lastCounter.order_user_id || lastCounter.user_id,
		user_id: userId,
		validity: defaultValidity,
		validityChanged: false,
		orderTypeChanged: false,
		forceFirm: !!lastCounter.forceFirm,
	};
};

export const mapPreviewDataToCounterView = (preview, formData, user, lastCounter) => {
	const { selectedProductPreset, contractPricing } = formData;
	const { inco_id, product } = selectedProductPreset;
	const orderRow = contractPricing[0];
	const [currency, price_unit] = orderRow.currencyUnit.split('/');
	const userId = user.session._key;
	const orderType =
		userId !== lastCounter.order_user_id
			? orderRow.type === OrderType.Sell
				? OrderType.Buy
				: OrderType.Sell
			: orderRow.type;

	const isOwner = userId === lastCounter.order_user_id;

	const spreadPriceFormat = selectedProductPreset.instruments.find(
		i => i.type === formData.instrument
	)?.spread_price_format;

	const isPayCashSpread = spreadPriceFormat === SpreadPriceFormat.PayCash;

	const spreadDetails =
		formData.instrument === PaperInstruments.Spread
			? {
					firstLegPrice: orderRow.firstLegPrice || '',
					type: lastCounter.order_type === OrderType.Buy ? OrderType.Sell : OrderType.Buy,
					firstLegFuturesMonth: lastCounter.firstLegFuturesMonth,
					firstLegMonth: lastCounter.firstLegMonth,
					secondLegFuturesMonth: lastCounter.secondLegFuturesMonth,
					secondLegMonth: lastCounter.secondLegMonth,
					spreadMeta: {
						orderCounterType: lastCounter.counter_order_type || lastCounter.order_type,
						user: lastCounter.user,
						shouldShowUser: !lastCounter.hidden || !!lastCounter.is_identity_revealed,
					},
					volume: isPayCashSpread ? orderRow.volume : orderRow.totalVolume,
			  }
			: {};

	const mappedData = {
		...preview[0],
		is_my_order: isOwner,
		// we consider countering as counterparty as a principal role counter mostly because `role` in counters always refer to order role
		role: lastCounter.role === Role.Broker && isOwner ? Role.Broker : Role.Principal,
		product,
		inco: {
			name: inco_id,
		},
		primary_ports: [
			{
				name:
					selectedProductPreset.loading_port?.name ||
					selectedProductPreset.discharging_port?.name ||
					'',
				country_id: selectedProductPreset.origin_country_id,
			},
		],
		environment: orderRow.environment,
		price: orderRow.price,
		currency,
		price_unit,
		// TODO: Rename to `counter_order_type` as it suits more
		order_type: orderType,
		futures_contract_date: orderRow.futuresMonth,
		origin_countries: [...selectedProductPreset?.origin_countries],
		shipment_type: selectedProductPreset.shipment_type,
		user: {
			_key: userId,
			avatar_color: user.avatar_color,
			name: user.session.name,
			first_name: user.session.first_name,
			last_name: user.session.last_name,
			company: {
				name: user.company_name,
				avatar_color: user.company_avatar_color,
			},
		},
		user_id: userId,
		order_owner_principal: {
			...(orderRow.principalObject && {
				hidden:
					orderRow.role === Role.Principal
						? orderRow.nameVisibility === NameVisibility.Hidden
						: orderRow.principalVisibility === NameVisibility.Hidden,
				...(orderRow.principalObject.user && {
					user: {
						_key: orderRow.principalObject.user._key,
						name: orderRow.principalObject.user.name,
					},
					company: {
						name: orderRow.principalObject.company.name,
						avatar_color: orderRow.principalObject.company.avatar_color,
					},
				}),
			}),
		},
		order_user_id: lastCounter.order_user_id || lastCounter.user_id,
		terms: selectedProductPreset.terms,
		grade: selectedProductPreset.grade,
		volume: orderRow.totalVolume,
		runs: orderRow.runs,
		is_counter_preview: true,
		...spreadDetails,
		// TODO: Unify with ...spreadDetails or include spreadDetails in spread_details
		spread_details: {
			price_format: spreadPriceFormat,
			spread_type: orderRow.spreadType,
		},
	};

	// NOTE: when the order is firm, the is_indicative flag doesn't come from BE and the comparison fails
	const lastCounterWithForcedIndicativeFlag = {
		...lastCounter,
		is_indicative: lastCounter.is_indicative || false,
	};

	const changes = getChangesBetweenCounters(mappedData, lastCounterWithForcedIndicativeFlag);

	return {
		...mappedData,
		changes,
	};
};
