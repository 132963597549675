/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
	CUSTOM_CONTRACT_TERMS,
	Environment,
	Market,
	NameVisibility,
	Role,
	tNameVisibility,
	tRole,
	PerformanceBondOptions,
	tEnvironmentAlternative,
} from 'src/constants/contract';
import { useWatchPhysicalFields } from '../../useWatchPhysicalFields';
import { useQuery } from 'react-query';
import { getCounterparties, listTerms } from 'src/_api';
import { useCustomContract } from './useCustomContract';
import { useDocuments } from './useDocuments';
import { DrawerContextKeys, useDrawerContext } from 'src/components/Drawer/DrawerContext';
import { mapCounterparties } from 'src/_helpers/mapCounterparties';
import { isCounterpartyAContact } from 'src/_helpers/isCounterpartyAContact';
import { OrderCreationPermissions, Permissions } from 'src/constants/permissions';
import { usePermissionContext } from 'src/containers/Permissions/PermissionsContext';

export const useAdditionalInfoSectionOptions = () => {
	const { t } = useTranslation();

	const { setValue } = useFormContext();

	const {
		productTypeValue,
		contractTermsValue,
		contractNumberValue,
		environmentValue,
		counterpartiesValue,
		isEditing,
		performanceBondValue,
		roleValue,
	} = useWatchPhysicalFields();

	const customContract = useCustomContract();

	const documents = useDocuments();

	const { hasPermission } = usePermissionContext();

	const {
		[DrawerContextKeys.createDrawer]: { otcRecipients },
	} = useDrawerContext();

	/** ENVIRONMENT */
	const environmentOptions = Object.values(Environment).map(environment => ({
		value: environment,
		text: tEnvironmentAlternative(t, environment),
	}));

	/** NAME VISIBILITY */
	const nameVisibilityOptions = Object.values(NameVisibility).map(environment => ({
		value: environment,
		text: tNameVisibility(t, environment),
	}));

	/** ROLE */
	const roleOptions = Object.values(Role).map(role => ({
		value: role,
		text: tRole(t, role),
	}));

	/** CONTRACT TERMS */
	const { data: contractTermsOptions = [], isLoading: isLoadingContractTerms } = useQuery(
		['terms', productTypeValue],
		() => listTerms(productTypeValue, Market.Physical),
		{
			select: list => {
				const options = list.map(({ _key, name }) => ({
					text: name,
					value: _key,
				}));
				return [
					{
						text: t('custom'),
						value: 'custom',
					},
					...options,
				];
			},
		}
	);

	useEffect(() => {
		setValue('contractTermsOptions', contractTermsOptions);
	}, [contractTermsOptions, setValue]);

	useEffect(() => {
		if (contractTermsValue !== CUSTOM_CONTRACT_TERMS && customContract.file) {
			customContract.onDelete(false);
		}
	}, [contractTermsValue, customContract]);

	/** CONTRACT NUMBER */
	useEffect(() => {
		if (contractTermsValue === null || contractTermsValue === CUSTOM_CONTRACT_TERMS) {
			setValue('contractNumber', '');
		}
	}, [contractTermsValue, setValue]);

	/** OTC COUNTERPARTIES */
	const { data: counterpartiesOptions, isLoading: isLoadingCounterparties } = useQuery(
		['order-otc-list'],
		() => getCounterparties(),
		{
			enabled: environmentValue === Environment.OTC,
			cacheTime: 0,
			staleTime: 0,
			select: ({ contacts, groups }) => {
				return {
					...mapCounterparties(t, contacts, groups),
					contacts,
					groups,
				};
			},
		}
	);

	useEffect(() => {
		if (environmentValue === Environment.Exchange) {
			setValue('counterparties', undefined);
			setValue('fullContactsList', []);
		}
	}, [environmentValue, setValue]);

	useEffect(() => {
		if (otcRecipients?.length) {
			setValue('environment', Environment.OTC);
			setValue('counterparties', otcRecipients);
		}
	}, [otcRecipients, setValue]);

	const mapUser = user => ({
		_key: user.contact_user_id,
		first_name: user.name,
		last_name: '',
		avatar_color: user.avatar_color,
		status: user.status,
		name: user.name,
		company_id: user.company_id,
		company: {
			_key: user.company_id,
			name: user.company_name,
			country_code: user.company_country_code,
			city: user.city,
		},
	});

	useEffect(() => {
		if (counterpartiesOptions && counterpartiesValue.length) {
			const fullList = [];
			counterpartiesValue.forEach(item => {
				if (item.users) {
					item.users.forEach(userId => {
						const userRecord = counterpartiesOptions.contacts.find(
							contact => contact.contact_user_id === userId
						);
						if (userRecord) {
							fullList.push(mapUser(userRecord));
						}
					});
				} else {
					const userRecord = counterpartiesOptions.contacts.find(contact => {
						const id = typeof item === 'string' ? item : item.id;
						return contact.contact_user_id === id;
					});
					if (userRecord) {
						fullList.push(mapUser(userRecord));
					} else {
						const groupRecord = counterpartiesOptions.groups.find(
							group => group._key === item
						);
						if (groupRecord && groupRecord.users) {
							groupRecord.users.forEach(userRecord => {
								fullList.push(mapUser(userRecord));
							});
						}
					}
				}
			});
			setValue('fullContactsList', fullList);
		}
	}, [counterpartiesOptions, counterpartiesValue, setValue]);

	useEffect(() => {
		if (!counterpartiesOptions || !counterpartiesValue || counterpartiesValue.length === 0) {
			return;
		}

		const approvedCounterparties = counterpartiesValue.filter(userId =>
			isCounterpartyAContact(
				userId,
				counterpartiesOptions.contacts,
				counterpartiesOptions.groups
			)
		);

		if (approvedCounterparties.length === 0) {
			setValue('counterparties', undefined);
			setValue('fullContactsList', []);
		}
	}, [counterpartiesOptions, counterpartiesValue, setValue]);

	const isBrokerOrder = roleValue === Role.Broker;

	useEffect(() => {
		if (isBrokerOrder) {
			setValue('nameVisibility', NameVisibility.Visible);
		}
	}, [isBrokerOrder, setValue]);

	useEffect(() => {
		if (!hasPermission(OrderCreationPermissions, true)) {
			if (hasPermission(Permissions.ORDER_CREATE_AS_PRINCIPAL)) {
				setValue('role', Role.Principal);
			} else {
				setValue('role', Role.Broker);
			}
		}
	}, [hasPermission, setValue]);

	return {
		environment: { options: environmentOptions, ready: true, disabled: isEditing },
		nameVisibility: { options: nameVisibilityOptions, ready: !isBrokerOrder },
		role: { options: roleOptions, ready: true },
		performanceBond: {
			options: PerformanceBondOptions,
			ready: true,
			hasValue: performanceBondValue,
		},
		contractTerms: {
			options: contractTermsOptions,
			ready: !isLoadingContractTerms,
			hasValue: contractTermsValue,
		},
		contractNumber: {
			enabled: contractTermsValue && contractTermsValue !== CUSTOM_CONTRACT_TERMS,
			hasValue: contractNumberValue,
		},
		customContract,
		counterparties: {
			readOnly: isEditing && environmentValue === Environment.OTC,
			visible: !isEditing && environmentValue === Environment.OTC,
			options: counterpartiesOptions?.listOptions,
			groups: counterpartiesOptions?.groupOptions,
			ready: !isLoadingCounterparties,
		},
		documents,
		canHideOptional:
			!contractTermsValue ||
			!(contractTermsValue !== CUSTOM_CONTRACT_TERMS && contractNumberValue) ||
			!performanceBondValue ||
			!documents.files.length,
	};
};
