/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { ROUTES } from 'src/constants/routes';
import { OrderCreationPermissions } from 'src/constants/permissions';

const forcePermissionsEnable = process.env.REACT_APP_ENABLE_PRIVILEGES;
const forcePermissionsDisable = process.env.REACT_APP_DISABLE_PRIVILEGES;

export const getPermissions = initialList => {
	const list = {};

	if (initialList) {
		initialList.forEach(name => (list[name] = true));
	}

	if (forcePermissionsEnable) {
		forcePermissionsEnable.split(',').forEach(name => (list[name] = true));
	}

	if (forcePermissionsDisable) {
		forcePermissionsDisable.split(',').forEach(name => {
			list[name] = false;
		});
	}

	return list;
};

export const getHomepage = requestData => {
	if (!Array.isArray(requestData?.permissions) || requestData.permissions.length === 0) {
		return ROUTES.execution;
	}

	return ROUTES.root;
};

export const canCreateOrderAsBrokerOrPrincipal = userPermissions =>
	OrderCreationPermissions.every(orderCreationPermission =>
		userPermissions.includes(orderCreationPermission)
	);
