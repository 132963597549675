/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RefreshIcon } from 'src/assets/icons/refresh_24.svg';
import { DrawerContextKeys, getHeaderTheme, useDrawerContext } from '../DrawerContext';
import { HeaderType } from '../constants';
import { ConfirmDiscardChangesModal } from 'src/components/CreateOrderDrawer/components/ConfirmDiscardChangesModal/ConfirmDiscardChangesModal';
import { Market } from 'src/constants/contract';
import { useMarket } from 'src/_routes/useActiveMarket';
import { CopyOrderButton } from 'src/components/CreateOrderDrawer/components/CopyOrderButton/CopyOrderButton';
import { CopyLastOrderButton } from 'src/components/CreateOrderDrawer/components/CopyOrderButton/CopyLastOrderButton';
import { useHighlightItemContext } from 'src/containers/HighlightItemProvider/HighlightItemProvider';
import { useGetLastOrderId } from './useGetLastOrderId';
import * as Styled from './styled';

export const DrawerHeader = ({
	contextKey = DrawerContextKeys.viewDrawer,
	hasMinimize,
	onRefresh,
	type,
	message,
	subtitle,
	isVisible,
	canShowCopyLast = false,
	orderMarket,
	GuideComponent,
}) => {
	const data = useDrawerContext();
	const {
		visibility,
		minimize,
		isConfirmationVisible,
		setConfirmationVisible,
		setOrderPreviewData,
		isFormDirty,
		setIsFormDirty,
		counterFormEnabled,
		isCounterConfirmationVisible,
		setCounterConfirmationVisible,
		isCounterFormDirty,
	} = data[contextKey];
	const [isMinimized, { toggle: toggleMinimized }] = minimize;
	const [, { close: closeDrawer }] = visibility;
	const [market, setMarket] = useMarket();
	const { t } = useTranslation();

	const { highlightItem } = useHighlightItemContext();

	const lastOrder = useGetLastOrderId({
		market: orderMarket,
		isEnabled: isVisible && canShowCopyLast && contextKey === DrawerContextKeys.createDrawer,
	});

	const handleCloseDrawer = () => {
		highlightItem(null);
		if (contextKey === DrawerContextKeys.viewDrawer) {
			const { setCurrentOrderInfo } = data[contextKey];

			if (counterFormEnabled) {
				if (isCounterFormDirty) {
					setCounterConfirmationVisible(true);
				} else {
					closeDrawer();
				}
			} else {
				setCurrentOrderInfo(null);
				closeDrawer();
			}
		} else if (contextKey === DrawerContextKeys.createDrawer && isFormDirty) {
			setConfirmationVisible(true);
		} else {
			closeDrawer();
		}
	};

	const handleDrawerClick = () => {
		if (isMinimized) {
			toggleMinimized();
		}
	};

	const { temporaryHeader } = useDrawerContext()[DrawerContextKeys.viewDrawer];

	const onCounterConfirmDischargeModal = () => {
		setCounterConfirmationVisible(false);
		closeDrawer();
	};

	const onConfirmDiscardModal = () => {
		const marketValue = market === Market.Physical ? Market.Paper : Market.Physical;

		setMarket(marketValue);
		setOrderPreviewData(null);
		setIsFormDirty(false);

		setConfirmationVisible(false);
		closeDrawer();
	};

	const typeToShow = temporaryHeader?.type || type;
	const messageToShow = temporaryHeader?.message || message;

	const shouldShowMinimizeButton = hasMinimize && !temporaryHeader;
	const shouldShowCopyButton = lastOrder && canShowCopyLast && !temporaryHeader;

	return (
		<Styled.DrawerHeader
			className="drawer-header"
			theme={getHeaderTheme(typeToShow)}
			onClick={handleDrawerClick}
			$isMinimized={isMinimized}
			data-test={temporaryHeader ? 'temporary-drawer-header' : 'drawer-header'}
		>
			<Styled.TextWrapper>
				<Styled.Title>
					<span>{messageToShow}</span>
					{!temporaryHeader && !!GuideComponent && <GuideComponent />}
				</Styled.Title>
				{!!subtitle && <Styled.Subtitle>{subtitle}</Styled.Subtitle>}
			</Styled.TextWrapper>
			<Styled.DrawerHeaderButtonContainer>
				{shouldShowCopyButton && (
					<CopyOrderButton
						Component={CopyLastOrderButton}
						orderId={lastOrder._key}
						environment={lastOrder.environment}
						market={lastOrder.market}
						recipients={lastOrder.recipients}
						shouldShowWarning={isFormDirty}
					/>
				)}
				{!!onRefresh && (
					<Styled.RefreshButton onClick={onRefresh}>
						<RefreshIcon />
						{t('refresh')}
					</Styled.RefreshButton>
				)}
				{shouldShowMinimizeButton && (
					<Styled.IconWrapper>
						<Styled.MinimizeButton
							onClick={isMinimized ? undefined : toggleMinimized}
							$isMinimized={isMinimized}
							data-test="minimize-button"
						/>
					</Styled.IconWrapper>
				)}
				<Styled.IconWrapper onClick={handleCloseDrawer}>
					<Styled.CloseButton
						className="drawer-close-button"
						data-test="close-button"
						$dark={typeToShow === HeaderType.Success}
					/>
				</Styled.IconWrapper>
			</Styled.DrawerHeaderButtonContainer>
			{isConfirmationVisible && (
				<ConfirmDiscardChangesModal
					onConfirm={onConfirmDiscardModal}
					onCancel={() => setConfirmationVisible(false)}
				/>
			)}
			{isCounterConfirmationVisible && (
				<ConfirmDiscardChangesModal
					onConfirm={onCounterConfirmDischargeModal}
					onCancel={() => setCounterConfirmationVisible(false)}
					isCounter
				/>
			)}
		</Styled.DrawerHeader>
	);
};
